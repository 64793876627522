#msg p{
    padding: 18px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    flex: 2;
    text-align:center;
}
#msg p a{
    color: white;
    text-decoration: underline;
}

.alert-Yellow div p, .alert-Yellow div p a, .alert-Yellow div svg{
    color: black  !important;
}

@media(max-width: 764px){
    #msg p{
        font-size: 10px;
    }
}