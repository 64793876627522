@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap);
#msg p{
    padding: 18px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
    -ms-flex: 2 1;
        flex: 2 1;
    text-align:center;
}
#msg p a{
    color: white;
    text-decoration: underline;
}

.alert-Yellow div p, .alert-Yellow div p a, .alert-Yellow div svg{
    color: black  !important;
}

@media(max-width: 764px){
    #msg p{
        font-size: 10px;
    }
}
.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 8px;
    left: 12px;
    height: 13px;
    width: 13px;
    background-color: #f3f3f3;
    border-radius: 50%;
    border: solid #9c9c9c 2px
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark, .radio-outer:hover input ~ .checkmark  {
    background-color: #ccc;
    ;
  }

  .radio-container:hover{
    border-bottom: 1px solid #862345;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-container input:checked ~ .checkmark{
    border: solid #862345 2px
  }
  
  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #862345;
  }
.col p {
  margin-bottom: 0rem !important;
}

html, body, #root, #root > div, #root > div >div{
    height: 100%;
}
p{font-weight: lighter;}
.page a{ 
    color: #A80534 !important;
    text-decoration: underline;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro";
}
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn:hover {
  /*background-color: #660000 !important;*/
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}

#work::-webkit-input-placeholder, .sm-placeholder::-webkit-input-placeholder, .css-y8sign-InputComponent::-webkit-input-placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
}

#work:-ms-input-placeholder, .sm-placeholder:-ms-input-placeholder, .css-y8sign-InputComponent:-ms-input-placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
}

#work::placeholder,
.sm-placeholder::placeholder,
.css-y8sign-InputComponent::placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
}

#mainSection p {
  color: #2e2d29;
  font-weight: "lighter";
  font-size: "24px";
}

.truncate-overflow {
  --max-lines: 3;
  position: relative;
  max-height: calc(calc(var(--lh) - 500) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.truncate {
  position: absolute;
  bottom: 0; /* "bottom" */
  right: 0; /* "right" */
}
.truncate-overflow::after {
  content: "  ";
  position: absolute;
  right: 0; /* "right" */
  width: 1rem;
  height: 4rem;
  background-color: white;
}

.alt-card header div {
  display: none;
}

.card-img-top {
  height: 196px !important;
}
/*.alt-card:hover header div, .overlay{
  
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3e3d3ddb;
    color: white;
  
}*/

/* Clearable text inputs */
.clearable {
  position: relative;
  display: inline-block;
}
.clearable input[type="text"] {
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
}
.clearable__clear {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 8px;
  font-style: normal;
  font-size: 1.2em;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.clearable input::-ms-clear {
  /* Remove IE default X */
  display: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}
li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

li.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/*slick*/
.slick-slide div div {
  display: block !important;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.slick-next {
  right: 35px;
}
.slick-prev {
  left: 35px;
  z-index: 1;
}
/*slick*/

.lower-parking-buttons:hover {
  background-color: #494f55 !important;
}

#input-group > :first-child {
  -ms-flex: 3 1;
      flex: 3 1;
}

.css-y8sign-InputComponent {
  width: 75% !important;
}
.css-148o527-ItemComponent.react-dropdown-select-item-selected,
.css-148o527-ItemComponent {
  background: inherit !important;
  color: inherit !important;
  color: #676767 !important;
}

.css-12zlm52-ReactDropdownSelect {
  border: 2px solid #9c9c9c !important;
  border-radius: 5px !important ;
}
.css-v1jrxw-ContentComponent {
  color: #676767 !important;
  font-weight: bold;
}

.input-group * .css-12zlm52-ReactDropdownSelect {
  border: 2px solid #9c9c9c !important;
  border-top-left-radius: 5px !important ;
  border-bottom-left-radius: 5px !important ;

  border-top-right-radius: 0px !important ;
  border-bottom-right-radius: 0px !important ;
}

.shuttles-items {
  max-height: 30em;
  min-height: 30em;
  overflow-y: scroll;
}

@media (max-width: 600px) {
  .shuttles-items {
    max-height: none;
    min-height: none;
    overflow-y: inherit;
  }
}

@media (min-width: 600px) {
  .no-padding {
    padding: 0px;
  }
}

svg {
  vertical-align: unset !important;
}

/**/
.preferred-text {
  display: -ms-flexbox;
  display: flex;
  padding: 7px 15%;
  border: solid #037a86 4px;
  border-radius: 40px;
  color: #037a86;
}

@media (max-width: 992px) {
  .preferred-text {
    display: -ms-flexbox;
    display: flex;
    padding: 7px 0 7px 18%;
    border: solid #037a86 4px;
    border-radius: 40px;
    color: #037a86;
  }

  .parking-image-container > div:first-child {
    display: none;
  }
}

/*Parking button*/

@media (max-width: 991px) {
  .parking-options {
    padding-left: 12px;
  }
}

@media (min-width: 992px) {
  .parking-options {
    padding-left: 3em;
  }
}

@media (min-width: 768px) {
  .mapboxgl-map {
    height: 640px;
  }
  .alts {
    padding: 3px !important ;
  }
}

@media (min-width: 1200px) {
  .alts {
    padding: 10px !important ;
  }
}
@media (max-width: 768px) {
  .preferred-img {
    display: none;
  }
  .preferred-text {
    display: -ms-flexbox;
    display: flex;
    border: solid #037a86 4px;
    border-radius: 40px;
    color: #037a86;
    padding: 3%;
  }

  .parking-image-container > div:nth-child(-n + 2) {
    display: none;
  }

  .alts {
    padding: 10px !important ;
  }
}

.react-dropdown-select-dropdown-handle.css-1yc4zyy-DropdownHandleComponent.e1vudypg0
  svg,
.react-dropdown-select-dropdown-handle.css-1aarvou-DropdownHandleComponent.e1vudypg0
  svg {
  fill: #9c9c9c;
}

.react-dropdown-select-clear.css-w49n55-ClearComponent.e11qlq5e0 {
  color: #9c9c9c;
}

/*_::-webkit-full-page-media, _:future, :root .safari_only {

  top: 3em !important;

}*/

img.safari_only {
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
div.safari_only {
  position: relative;
}
_::-webkit-full-page-media,
_:future,
:root .safari_only,
div.safari_only {
  padding: 0px !important;
}
div.dropdown .btn {
  background-color: #a70c36 !important;
  border-color: #a70c36 !important;
}

.dropdown-item:hover {
  background-color: #a70c36;
}

.dropdown-item a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

input::-webkit-input-placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.5;
}

input:-ms-input-placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.5;
}

input::placeholder {
  color: #c6c3c3;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .faq-question div.col {
    font-size: 14px;
  }
  .faq-question img {
    min-width: 100px;
    max-width: 100px;
  }
}

