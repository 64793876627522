.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 8px;
    left: 12px;
    height: 13px;
    width: 13px;
    background-color: #f3f3f3;
    border-radius: 50%;
    border: solid #9c9c9c 2px
  }
  
  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark, .radio-outer:hover input ~ .checkmark  {
    background-color: #ccc;
    ;
  }

  .radio-container:hover{
    border-bottom: 1px solid #862345;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-container input:checked ~ .checkmark{
    border: solid #862345 2px
  }
  
  /* Style the indicator (dot/circle) */
  .radio-container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #862345;
  }